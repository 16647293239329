<template>
  <section>
    <!-- Titulo de la vista -->
    <v-row>
      <v-col class="text-h6 secondary--text">
        Perfil proveedor - Agregar Accionistas y representante legal</v-col
      >
    </v-row>

    <!-- Tabs para agregar accionistas de forma individual y por medio de archivo excel -->
    <v-row v-if="haveRole('ROLE_PROVEEDOR')">
      <v-col cols="12">
        <v-tabs background-color="bgMinsal">
          <v-tab class="secondary-text"
            ><v-icon>mdi-account </v-icon> Agregar accionista</v-tab
          >

          <v-tab-item>
            <agregar-accionista-v2-component></agregar-accionista-v2-component>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <!-- Tabla accionistas -->
    <v-row>
      <v-col cols="12">
        <tabla-accionistas></tabla-accionistas>
      </v-col>
    </v-row>

    <!-- Botón de regresar -->
    <v-row>
      <v-col cols="12">
        <v-btn class="mt-5" color="primary" outlined @click="regresar">
          Regresar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import TablaAccionistas from "./components/TablaAccionistasComponent.vue";
import AgregarAccionistaV2Component from "./components/AgregarAccionistaV2Component.vue";
export default {
  name: "agregarAccionistasAsociadosView",
  components: {
    AgregarAccionistaV2Component,
    TablaAccionistas,
  },
  data: () => ({}),
  methods: {
    regresar() {
      this.$router.back();
      // Navegar a la ruta anterior
    },
  },
};
</script>
<style lang=""></style>
